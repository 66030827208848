<template>
<div>
    <label>Phone number:</label>
  <input v-model="number" placeholder="number?" />
</div>
<div>
    <label>Message:</label>
  <textarea v-model="message" placeholder="message?" />
</div>
  <a v-bind:href="url" target="_blank">{{url}}</a>
</template>

<script>
export default {
  name: "WhatsApp",
  data() {
    return {
      message: "",
      number: "",
    };
  },
  computed: {
    url() {
      return "https://api.whatsapp.com/send?phone=" + this.number + "&text=" + this.message;
    },
  },
};
</script>

<style>

</style>